import React from 'react';

export class ProductCategoryRow extends React.Component<any, any> {
	render() {
		const category = this.props.category;
		return (
			<tr>
				<th
					//@ts-ignore
					colSpan="2">
					{category}
				</th>
			</tr>
		);
	}
}

export class ProductRow extends React.Component<any, any> {
	render() {
		const product = this.props.product;
		const name = product.stocked ? product.name : <span style={{color: 'red'}}> {product.name} </span>;
		
		return (
			<tr>
				<td>{name}</td>
				<td>{product.price}</td>
			</tr>
		);
	}
}

export class ProductTable extends React.Component<any, any> {
	render() {
		const filterText = this.props.filterText;
		const inStockOnly = this.props.inStockOnly;
		
		const rows: any = [];
		let lastCategory: any = null;
		
		this.props.products.forEach((product: any) => {
			if (product.name.toLowerCase().indexOf(filterText.toLowerCase()) === -1) return;
			if (inStockOnly && !product.stocked) return;
			if (product.category !== lastCategory) {
				rows.push(<ProductCategoryRow category={product.category} key={product.category}/>);
			}
			rows.push( <ProductRow product={product} key={product.name}/> );
			lastCategory = product.category;
		});
		
		return (
			<table>
				<thead>
				<tr>
					<th>Name</th>
					<th>Price</th>
				</tr>
				</thead>
				<tbody>{rows}</tbody>
			</table>
		);
	}
}

export class SearchBar extends React.Component<any, any> {
	constructor(props: any) {
		super(props);
		this.handleFilterTextChange = this.handleFilterTextChange.bind(this);
		this.handleInStockChange = this.handleInStockChange.bind(this);
	}
	
	handleFilterTextChange(e: any) {
		this.props.onFilterTextChange(e.target.value);
	}
	
	handleInStockChange(e: any) {
		this.props.onInStockChange(e.target.checked);
	}
	
	render() {
		return (
			<form>
				<input
					type="text"
					placeholder="Search..."
					value={this.props.filterText}
					onChange={this.handleFilterTextChange}
				/>
				<p>
					<input
						type="checkbox"
						checked={this.props.inStockOnly}
						onChange={this.handleInStockChange}
					/>
					{' '}
					Only show products in stock
				</p>
			</form>
		);
	}
}

export class FilterableProductTable extends React.Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			filterText: '',
			inStockOnly: false
		};
		
		this.handleFilterTextChange = this.handleFilterTextChange.bind(this);
		this.handleInStockChange = this.handleInStockChange.bind(this);
	}
	
	handleFilterTextChange(filterText: any) {
		this.setState({
			filterText: filterText
		});
	}
	
	handleInStockChange(inStockOnly: any) {
		this.setState({
			inStockOnly: inStockOnly
		});
	}
	
	render() {
		return (
			<div>
				<SearchBar
					filterText={this.state.filterText}
					inStockOnly={this.state.inStockOnly}
					onFilterTextChange={this.handleFilterTextChange}
					onInStockChange={this.handleInStockChange}
				/>
				<ProductTable
					products={this.props.products}
					filterText={this.state.filterText}
					inStockOnly={this.state.inStockOnly}
				/>
			</div>
		);
	}
}
