import React from 'react';

const scaleNames: any = {c: 'Celsius', f: 'Fahrenheit'};

export default class TemperatureInput extends React.Component<any, any> {
	constructor(props: any) {
		super(props);
		this.handleChange = this.handleChange.bind(this);
		this.state = {temperature: ''};
	}
	
	handleChange(e: any) {
		this.props.onTemperatureChange(e.target.value);
	}
	
	render() {
		// @ts-ignore
		const temperature = this.props.temperature;
		// @ts-ignore
		const scale = this.props.scale;
		return (
			<fieldset>
				<legend>Enter temperature in {scaleNames[scale]}:</legend>
				<input value={temperature} onChange={this.handleChange}/>
			</fieldset>
		);
	}
}
