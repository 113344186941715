import React from 'react';
import logo from './logo.svg';
import './Styles/App.css';
import {
	Header,
	LoginControl,
	Clock,
	Numbers,
	Navigation,
	UserInputForm,
	Footer,
	Calculator, Welcome, Contacts, Chat, Searchable
} from './Components/index';
import {SplitPane} from './Elements/Panes'
import * as fakeData from './FakeData/index'

export default function App() {
	const contacts = fakeData.contacts;
	const messages = fakeData.messages;
	return (
		<div className="App">
			<Header/>
			<Welcome/>
			<Searchable.FilterableProductTable products={fakeData.products} />
			<SplitPane
				left={ <Contacts contacts={contacts} /> }
				right={ <Chat messages={messages} /> }
			/>
			<div><img src={logo} className="App-logo" alt="logo"/></div>
			<LoginControl/>
			<Clock/>
			<Calculator/>
			<Numbers numbers={fakeData.numbers}/>
			<header className="App-header">
				<div>
					<Navigation/>
				</div>
				<div>
					<UserInputForm/>
				</div>
			</header>
			<Footer/>
		</div>
	);
}
