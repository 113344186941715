import React from 'react';
import {LoginButton, LogoutButton, Greeting} from '../Elements/LoginControlElements'

interface LoginState {
	isLoggedIn: boolean,
	showWarning: boolean
}

export default class LoginControl extends React.Component<{}, LoginState> {
	constructor(props: any) {
		super(props);
		this.handleLoginClick = this.handleLoginClick.bind(this);
		this.handleLogoutClick = this.handleLogoutClick.bind(this);
		this.state = {
			isLoggedIn: false,
			showWarning: true
		};
	}
	
	handleLoginClick() {
		this.setState({
			isLoggedIn: true,
			// @ts-ignore
			showWarning: true
		});
	}
	
	handleLogoutClick() {
		this.setState({
			isLoggedIn: false,
			// @ts-ignore
			showWarning: false
		});
	}
	
	render() {
		const isLoggedIn = this.state.isLoggedIn;
		let button;
		if (isLoggedIn) {
			button = <LogoutButton onClick={this.handleLogoutClick}/>;
		} else {
			button = <LoginButton onClick={this.handleLoginClick}/>;
		}
		return (
			<div>
				<div>
					<Greeting isLoggedIn={isLoggedIn}/> {button}
				</div>
			</div>
		);
	}
}
