import React from 'react';
import '../Styles/Panes.css';

export function SplitPane(props: any) {
	return (
		<div className="SplitPane">
			<div className="SplitPane-left">{props.left}</div>
			<div className="SplitPane-right">{props.right}</div>
		</div>
	);
}
