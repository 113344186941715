import products from './products';
import messages from './messages';
import contacts from './contacts';
const numbers = [1, 2, 3, 4, 5];

export {
	contacts,
	messages,
	numbers,
	products
}
