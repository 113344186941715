import React from 'react';
import '../Styles/App.css';
import ClockState from '../States/ClockStates';
import {FormattedDate} from '../Elements/FormattedDate';

export default class Clock extends React.Component<{}, ClockState> {
	constructor(props: any) {
		super(props);
		this.state = {date: new Date()};
	}
	
	componentDidMount() {
		// @ts-ignore
		this.timerID = setInterval(() => this.tick(), 1000);
	}
	
	componentWillUnmount() {
		// @ts-ignore
		clearInterval(this.timerID);
	}
	
	tick() {
		this.setState({date: new Date()});
	}
	
	render() {
		return (
			<div>
				<h1>Hello, world!</h1>
				<FormattedDate date={this.state.date}/>
			</div>
		);
	}
}
