import React from 'react';

export default class Numbers extends React.Component<any, any> {
	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(props: any) {
		super(props);
	}
	render() {
		const numbers = this.props.numbers;
		const listItems = numbers.map((number:any) => <li key={number.toString()}>{number}</li>);
		return <ul>{listItems}</ul>;
	}
}
