import React from 'react';
import '../Styles/App.css';
import {Mailbox} from '../Elements/MessagesElements';

const messages = ['React', 'Re: React', 'Re:Re: React'];

export default class Navigation extends React.Component<{}, {}> {
	constructor(props: any) {
		super(props);
		this.state = {
			isToggleOn: true
		};
		// This binding is necessary to make `this` work in the callback
		this.handleClick = this.handleClick.bind(this);
	}
	
	handleClick() {
		this.setState(state => ({
			// @ts-ignore
			isToggleOn: !state.isToggleOn
		}));
	}
	
	// use arrow functions if you don't want to use `this.<someMethod>.bind(this)` in the constructor.
	// handleClick = () => {
	// 	this.setState(state => ({
	// 		// @ts-ignore
	// 		isToggleOn: !state.isToggleOn
	// 	}));
	// };
	
	deleteRow(id: number, e: any) {
		// console.log('id', id);
	}
	
	render() {
		return (
			<div>
				<div>
					<Mailbox unreadMessages={messages} />
				</div>
				<div>
					<ul>
						<li id="1" onClick={(e) => this.deleteRow(1, e)}>one</li>
						<li id="2" onClick={(e) => this.deleteRow(2, e)}>two</li>
					</ul>
				</div>
				<div>
					<button onClick={this.handleClick}>
						{
							// @ts-ignore
							this.state.isToggleOn ? 'ON' : 'OFF'
						}
					</button>
				</div>
			</div>
		);
	}
}
