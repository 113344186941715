import React from 'react';

export default class Chat extends React.Component<any, any> {
	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(props: any) {
		super(props);
	}
	
	render() {
		const messages = this.props.messages.map((res: any) => <li key={res.id.toString()}>{res.message}</li>)
		return(
			<div>
				<h2>Chat:</h2>
				<ul>{messages}</ul>
			</div>
		);
	}
}
