import React from 'react';

export default class Footer extends React.Component<any, any> {
	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(props: any) {
		super(props);
	}
	
	render() {
		return (
			<div>
				<h1>Footer</h1>
			</div>
		);
	}
}
