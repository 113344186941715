import React from 'react';

export default class UserInputForm extends React.Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			userName: '',
			description: '',
			favorite: 'grapefruit'
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}
	
	handleChange(event: any) {
		this.setState({
			[event.target.name]: event.target.value
		});
	}
	
	handleSubmit(event: any) {
		alert(
			'Username: ' + this.state.userName +
			'\n' +
			'Description: ' + this.state.description +
			'\n' +
			'Favorite: ' + this.state.favorite
		);
		event.preventDefault();
	}
	
	render() {
		return(
			<form onSubmit={this.handleSubmit}>
				<label>
					Name:
					<input type="text" name="userName" value={this.state.userName} onChange={this.handleChange} />
				</label>
				<br/>
				<label>
					Essay:
					<textarea name="description" value={this.state.description} onChange={this.handleChange} />
				</label>
				<br/>
				<label>
					Pick your favorite flavor:
					<select name="favorite" value={this.state.favorite} onChange={this.handleChange} >
						<option value="grapefruit">Grapefruit</option>
						<option value="lime">Lime</option>
						<option value="coconut">Coconut</option>
						<option value="mango">Mango</option>
					</select>
				</label>
				<br/>
				<input type="submit" value="Submit" />
			</form>
		);
	}
}
