import React from 'react';

export default class Contacts extends React.Component<any, any> {
	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(props: any) {
		super(props);
	}
	
	render() {
		const contacts = this.props.contacts.map((res:any) => <li key={res.id.toString()}>{res.name}</li>);
		return (
			<div>
				<h2>Contacts:</h2>
				<ul>{contacts}</ul>
			</div>
		);
	}
}
