import React from 'react';

export function Mailbox(props: any) {
	const unreadMessages = props.unreadMessages;
	return (
		<div>
			<h1>Hello!</h1>
			{
				unreadMessages.length > 0 && <h2> You have { unreadMessages.length } unread messages. </h2>
			}
		</div>
	);
}
