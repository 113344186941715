import React from 'react';

export function LoginButton(props: any) {
	return (
		<button onClick={props.onClick}>
			Login
		</button>
	);
}

export function LogoutButton(props: any) {
	return (
		<button onClick={props.onClick}>
			Logout
		</button>
	);
}

function UserGreetingMessage(props: any) {
	return <div>The user is <b>{props.isLoggedIn ? 'currently' : 'not'}</b> logged in.</div>;
}

export function UserGreeting(props: any) {
	return <div><h1>Welcome back!</h1><UserGreetingMessage isLoggedIn={props.isLoggedIn}/></div>;
}

export function GuestGreeting(props: any) {
	return <div><h1>Please sign up.</h1><UserGreetingMessage isLoggedIn={props.isLoggedIn}/></div>;
}

export function Greeting(props: any) {
	const isLoggedIn = props.isLoggedIn;
	if (isLoggedIn) {
		return (
			<div>
				<UserGreeting isLoggedIn={isLoggedIn} />
			</div>
		);
	}
	return <GuestGreeting isLoggedIn={isLoggedIn} />;
}
