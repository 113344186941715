import React from 'react';

import {WarningBanner} from '../Elements/Warnings';

export default class Header extends React.Component<any, {warn: boolean}> {
	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(props: any) {
		super(props);
	}
	render() {
		const warn = false;
		return (
			<div>
				<h2>some header</h2>
				<WarningBanner warn={warn} message="see this?"/>
			</div>
		);
	}
}
